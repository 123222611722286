import React, { useState } from "react";
import Modal  from "react-modal";
import {Helmet} from "react-helmet";

Modal.setAppElement("#root");

export const Iclchat = (props) => {
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [videoIsOpen,setVideoIsOpen] = useState(false);  
  const [isLoading,setLoading] = useState(true);
  const videoId='https://iclvideos.s3.ap-south-1.amazonaws.com/chatbots/Support_ChatBot.mp4';

  return (  
  <div id="iclchat" className="text-center">
      <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>ICL chatBot</title> */}
      <link rel="canonical" href="https://eximon.com/#iclchat" />
      <meta name="description" content="AI Chatbot Development Services. Transform Your Business with Intelligent Chatbots" />
      <meta name="keywords" content="AI Chatbot Development, Chatbot Services, Artificial Intelligence Solutions, Custom Chatbot Development, AI-Powered Chatbots, Chatbot Development Company, Enterprise Chatbot Solutions, AI Chatbot Platform, Customer Service Chatbots, AI Customer Support, Conversational AI, Natural Language Processing, Machine Learning Chatbots, Voice-Enabled AI Chatbots, Chatbot for Business, Automated Customer Service, AI Virtual Assistant, Chatbot Integration Services, Chatbot for Sales and Marketing, AI Chatbot for E-commerce" />
      <meta property="og:title" content="ICL chatBot" />
    </Helmet>

      <div className="container">
        <div className="section-title">
          <h1>ICL ChatBot</h1>
          <h2>AI Chatbot Development Services. Transform Your Business with Intelligent Chatbots</h2>
          <div className="btn-group">
          <button onClick={()=>setModalIsOpen(true)} type="button" className="btn btn-custom btn-lg" >
                  Try me
                </button> 
                <Modal 
        isOpen={modalIsOpen} 
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            right: '0px',
            bottom: '20px',
            width: '400px',
            height: '500px'
          }
        }}
      >
                {isLoading && <div style={{fontWeight:"bold"}}>Please wait loading ...</div>}
        <iframe 
          title="ICL chatBot" 
          src="https://dtegnbzmkqshu.cloudfront.net" 
          allowFullScreen 
          style={{ width: '100%', height: '90%' }} onLoad={()=>setLoading(false)}
        />
           {!isLoading && 
                        <button style={{marginTop:"5px"}} onClick={()=>setModalIsOpen(false)} type="button" className="btn btn-custom btn-lg" >
                  Close
                </button> 
}               
        </Modal>
        <button onClick={()=>setVideoIsOpen(true)} type="button" className="btn btn-custom btn-lg" >
                  Demo
                </button>               
                <Modal 
        isOpen={videoIsOpen} 
        onRequestClose={() => setVideoIsOpen(false)}
        style={{
          content: {
            position: 'absolute',
            top: 'auto',
            left: '10px',
            right: 'auto',
            bottom: '20px',
            width: '70%',
            height: '60%'
          }
        }}
      >

        {isLoading && <div style={{fontWeight:"bold"}}>Please wait loading ...</div>}
        <iframe 
          title="ICL ChatBot Demo" 
          src={videoId}  
          allowFullScreen 
          style={{ width: '100%', height: '90%' }} onLoad={()=>setLoading(false)}
        />
        {!isLoading && 
                        <button onClick={()=>{setVideoIsOpen(false);setLoading(true);}} type="button" className="btn btn-custom btn-lg" >
                  Close
                </button> 
}               
        </Modal>
                </div>                
        </div>       
          <div className="row" style={{marginTop:'10px'}}>
          {
          props.data
            ? props.data.map((d, i) => (
                <div key={`${d.Title}-${i}`} className="col-md-4">
                  <div className="caption">
                    {d.Title && <h2>{d.Title}</h2>}
                    </div>
                    <div>
                    {d.Subtitle && <h3>{d.Subtitle}</h3>}
                    <h4>{d.Paragraph}</h4>
                    </div>
                  </div>
                  ))
                :"loading"}
        </div>
      </div>
    </div>
    
  );
};
