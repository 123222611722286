import React from "react";
import { Helmet } from "react-helmet";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
                  <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>Meet the Team</title> */}
      <link rel="canonical" href="https://eximon.com/#Team" />
      <meta name="description" content="Meet our dedicated and experienced team. Learn about their skills, qualifications, and the roles they play in delivering high-quality services." />
      <meta name="keywords" content="Our Team, Team Members, Experienced Professionals, Skilled Team, Team Qualifications, Team Roles, Meet Our Team" />
      <meta property="og:title" content="What our clients say" />
    </Helmet>

      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h1>Meet the Team</h1>
          <h2>
          Meet our dedicated and experienced team. Learn about their skills and the roles they play.
          </h2>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
