import React from "react";
import { Helmet } from "react-helmet";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
                  <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>What our clients say</title> */}
      <link rel="canonical" href="https://eximon.com/#testimonials" />
      <meta name="description" content="Read testimonials from our satisfied customers who have benefited from our services. Discover how we’ve helped businesses succeed with our solutions." />
      <meta name="keywords" content="Customer Testimonials, Client Feedback, Positive Reviews, Success Stories, Customer Satisfaction, Business Solutions Success, Service Testimonials" />
      <meta property="og:title" content="What our clients say" />
    </Helmet>
      <div className="container">
        <div className="section-title text-center">
          <h1>What our clients say</h1>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
