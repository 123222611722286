import { Image } from "./image";
import React from "react";
import { Helmet } from "react-helmet";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
                        <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>Gallery</title> */}
      <link rel="canonical" href="https://eximon.com/#gallery" />
      <meta name="description" content="Explore our gallery to see our work, products, and services in action. Get a visual insight into what we do and how we do it." />
      <meta name="keywords" content="Our Gallery, Visual Content, Work Showcase, Product Images, Service Images, Visual Portfolio, Image Gallery" />
      <meta property="og:title" content="What our clients say" />
    </Helmet>

      <div className="container">
        <div className="section-title">
          <h1>Gallery</h1>
         <h2>Explore our gallery to see our work, products, and services in action.</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
