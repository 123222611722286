import React, { useState, useEffect } from "react";
import { emssData, icligmData,saasData, cloudData } from "./about";
import { Helmet } from "react-helmet";

export const Features = (props) => {
  const [loading, setLoading] = useState(true); // Initialize loading state
  const apiData = [emssData,icligmData,saasData,cloudData];
  const maxRetryAttempts = 5; // Maximum number of retry attempts
  const retryInterval = 1000; // Retry interval in milliseconds (e.g., 1 second)

  useEffect(() => {
    const fetchData = async (retryCount) => {
      if (emssData && icligmData && saasData && cloudData) {
        setLoading(false); // Data is available, set loading to false
      } else if (retryCount < maxRetryAttempts) {
        setTimeout(() => {
          fetchData(retryCount + 1); // Retry with an incremented count
        }, retryInterval);
      }
    };

    fetchData(0); // Start fetching with retry count set to 0
  }, []);

  return (
    <div id="features" className="text-center">
      <Helmet>
      <meta charSet="utf-8" lang="en" />
      {/* <title>Features</title> */}
      <link rel="canonical" href="https://eximon.com/#features" />
      <meta name="description" content="Software Database Cloud and AI services." data-react-helmet="true"/>
      <meta name="keywords" content="EMSS Software, ICLIGM Software, ICL chatBot (AI), SaaS (pay per use), Cloud services" />
      <meta property="og:title" content="Features" />
    </Helmet>
      <div className="container">
        {/* <div className="col-md-10 col-md-offset-1 section-title"> */}
        <div className="section-title">          
          <h1>Features</h1>
        </div>
        <div className="row">
          {loading
            ? "Loading..." // Display loading message
            : props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  <i className={d.icon}></i>
                  <h3><a href={d.link}>{d.title}</a></h3>
                  <div dangerouslySetInnerHTML={{ __html: apiData[i] }} />
                </div>
              ))
            : "No data available"}
        </div>
      </div> 
    </div>
  );
};
